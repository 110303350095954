import React from 'react';
import {useState} from 'react';
import './Stairs.css';
import { Alert, Form, Button, Row, Col, CloseButton} from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './paint.css'

export default function Floor() {

    const [complated, setComplated] = useState(false);
    const [error, setError] = useState(null);
    const [ isOpen, setIsOpen ] = useState(false); 

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phoneNumber: '',
            address: '',
            city: '',
            province: '',
            postalCode: '',
            description: '',
            isReady: false
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Name is required"),
            email: Yup.string().email("invalid email address").required("Email is required"),
            phoneNumber: Yup.string().matches(/^[0-9]{10}$/, "The phone number must be 10 digits").required("Phone number is required"),
            address: Yup.string().min(0).max(135).required("Address is required"),
            city: Yup.string().min(0).max(100, "Must be at most 100 characters").required("City is required"),
            province: Yup.string().required("Province is required"),
            postalCode: Yup.string().required("Postal code is required"),
            description: Yup.string().min(0).max(400, "Must be at most 400 characters"),
        }), 
        onSubmit: values => {
            // sent to backend api 
            console.log(values);
            fetch('https://api-precisionfix.azurewebsites.net/paint/estimate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(values),
            }).then(res => {
                if(res.status === 200) {
                    setComplated(true);
                } else {
                    throw new Error('form not valid');
                }
            }).catch(err => {
                console.log('Error submitting form', err);
                setError(err);
            });
        }
    });

    if(complated) {
        return (
            <div id="alert">
            <Alert variant="success">
              <Alert.Heading>Form Successfully submitted</Alert.Heading>
              <p>Thank you for taking the first! Your form has been submitted, and we appreciate your interest. Out team will review your submisison and get in touch with you soon</p>
              <hr />
            <div className="d-flex justify-content-end">
            <a href={/home/}><Button variant="outline-success">
                get back to homepage
            </Button></a>
            </div>
            </Alert>
            </div>
        );
    }
    if(error) {
        console.log("Error", error);
    }
    return (
    <>
    <div id="paint-wallpaper-bg">
    <div id="floor-intro"> 
        <h2>Transform Your Home with Professional Painting Services</h2>
        <p>Welcome to PrecisionFix, where we bring your vision to life through expert painting services. With A decade of experience in the industry, we are dedicated to delivering exceptional results and transforming homes into beautiful spaces. Our team of skilled painters is committed to professionalism, attention to detail, and top-quality craftsmanship. Whether you're looking to refresh the interior of your home, enhance your curb appeal with a fresh coat of paint, or add a unique finish to a specialty project, we have the expertise and creativity to exceed your expectations. Trust us to bring color and vibrancy to your living spaces, providing a lasting impact and a reflection of your personal style. Experience the difference with PrecisionFix and let us turn your painting vision into a reality.</p>
        <button onClick={() => { setIsOpen(true)}}>Schedule an estimate</button>
    </div>
    </div>


    <div id="floor-solutions">
        <h3>Painting Services That Transform Your Home</h3>
        <p id="floor-solu-p">At PrecisionFix
        , we offer a wide range of painting services to transform your home and bring your vision to life. Our skilled team of painters combines expertise, attention to detail, and quality materials to deliver exceptional results. Whether you're looking to refresh a single room or revitalize your entire home, we have the experience and creativity to exceed your expectations.</p>
        <div className="floor-solution">
            <span className="flex-left">
            <img src={require('./public/interior.jpg')} alt="hardwood floor"/>
            </span>
            <span className="flex-right">
            <h2>Interior Painting</h2>
            <p>From cozy bedrooms to inviting living spaces, our interior painting services help create the atmosphere you desire. We meticulously prepare surfaces, select premium paints, and use proven techniques to achieve smooth, flawless finishes. Our painters pay attention to every detail, ensuring crisp lines and even coverage, so you can enjoy a fresh and vibrant interior that reflects your personal style.</p>
            </span>
        </div>
        <div className="floor-solution">
            <span className="flex-left">
            <img src={require('./public/exterior.jpg')} alt="hardwood floor"/>
            </span>
            <span className="flex-right">
            <h2>Exterior Painting </h2>
            <p>Enhance your home's curb appeal with our professional exterior painting services. We understand the importance of protecting your home from the elements while providing a beautiful and lasting finish. Our team meticulously prepares surfaces, addresses any repairs or damages, and applies weather-resistant paints to ensure your home stands out in the neighborhood. Experience a revitalized exterior that makes a lasting impression.</p>
            </span>
        </div>
        <div className="floor-solution">
            <span className="flex-left">
            <img src={require('./public/special.jpg')} alt="hardwood floor"/>
            </span>
            <span className="flex-right">
            <h2>Specialty Finishes </h2>
            <p>Looking to add a touch of uniqueness to your space? Our specialty finishes are designed to create stunning visual effects and enhance the ambiance of any room. Whether you desire textured walls, faux finishes, decorative accents, or unique color schemes, our skilled painters have the expertise to bring your vision to life. Let us transform your walls into works of art and make your home truly one-of-a-kind.</p>
            </span>
        </div>
        <div className="floor-solution">
            <span className="flex-left">
            <img src={require('./public/consultation.jpg')} alt="hardwood floor"/>
            </span>
            <span className="flex-right">
            <h2>Additional Services</h2>
            <p>In addition to our core painting services, we offer a range of additional services to cater to your specific needs. From wallpaper removal and surface preparation to cabinet refinishing and color consultations, we provide comprehensive solutions for all your painting requirements. Our goal is to make the painting process seamless and stress-free, ensuring your complete satisfaction.</p>
            </span>
        </div>
        </div>

        <div className="schedule-estimate"> 
            <h5>Experience the difference with PrecisionFix. Contact us today for a free consultation and estimate. Let us help you transform your home with our professional painting services.</h5>
            <button onClick={() => setIsOpen(true)}>Schedule an Estimate</button>
        </div>

        <div id="service-process">
        <div id="process-intro">
            <h1>Our Painting Process: Turning Your Vision into Reality</h1>
            <p>At PrecisionFix, we follow a meticulous painting process to ensure exceptional results and a smooth experience for our clients. Our step-by-step approach, combined with our team's attention to detail and professionalism, guarantees a flawless and long-lasting finish. Here's an overview of our painting process:</p>
        </div>
        <div className="service-process">
            <h4>Step 1: Consultation and Color Selection </h4>
            <p>During an initial consultation, our experts will discuss your painting project in detail. We'll listen to your vision, offer professional advice, and provide color samples to help you choose the perfect shades for your space.</p>
        </div>
        <div className="service-process"> 
            <h4>Step 2: Surface Preparation </h4>
            <p>Our skilled painters will thoroughly prepare the surfaces to be painted. This includes cleaning, repairing any damages, and ensuring a smooth and even foundation for the paint.</p>
        </div>
        <div className="service-process">
            <h4>Step 3: Priming</h4>
            <p>We apply a high-quality primer to enhance paint adhesion, improve coverage, and promote a uniform finish. This step ensures the longevity and durability of the paint job.</p>
        </div>
        <div className="service-process">
            <h4>Step 4: Paint Application </h4>
            <p>Using professional-grade tools and techniques, we expertly apply the chosen paint to the prepared surfaces. Our team pays careful attention to detail, ensuring consistent coverage and smooth, streak-free finishes.</p>
        </div>
        <div className="service-process">
            <h4>Step 5: Precision and Detail Work </h4>
            <p>We meticulously address any intricate areas, such as corners, trim, or edges. Our painters utilize precise techniques and tools to achieve crisp lines and seamless transitions between colors or surfaces.</p>
        </div>
        <div className="service-process">
            <h4>Step 6: Final Touches and Clean-Up </h4>
            <p>We conduct a thorough inspection of the painted surfaces, making any necessary touch-ups to ensure a flawless result. Our team will then clean up the workspace, removing any debris, and returning the area to its original condition.</p>
        </div>
        <div className="service-process">
            <h4>Step 7: Client Walkthrough and Satisfaction </h4>
            <p>Once the painting is complete, we invite you for a walkthrough to ensure your satisfaction. We value your feedback and will address any concerns promptly to ensure you're delighted with the final outcome.</p>
        </div>
    </div>
    { isOpen && (
     <div className="container-form">
        {
            error ? <h1>Error</h1> : null
        }
    
        <div id="stair-form">
        <Form onSubmit={formik.handleSubmit}>
        <CloseButton id="close_button" onClick={() => setIsOpen(false)}/>
            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridFirstName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control 
                        name="name"
                        type="text" 
                        placeholder="Enter name" 
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />
                  {
                      formik.touched.name && formik.errors.name ? (
                        <Form.Text className="text-muted">
                            {formik.errors.name}
                      </Form.Text>
                      ) : null
                  }
        </Form.Group>
               
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control                         
                        name="email"
                        type="email" 
                        placeholder="Enter email" 
                        value={formik.values.email}
                        onChange={formik.handleChange}
                />
                  {
                      formik.touched.email && formik.errors.email ? (
                        <Form.Text className="text-muted">
                            {formik.errors.email}
                      </Form.Text>
                      ) : null
                  }
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPhoneNumber">
                <Form.Label>Phone number</Form.Label>
                <Form.Control                         
                        name="phoneNumber"
                        type="phone" 
                        placeholder="Enter phone number"
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        />
                          {
                      formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                        <Form.Text className="text-muted">
                            {formik.errors.phoneNumber}
                      </Form.Text>
                      ) : null
                  }
                </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Address</Form.Label>
                <Form.Control                        
                        name="address"
                        placeholder="1234 Main St" 
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        />
                          {
                            formik.touched.address && formik.errors.address ? (
                                <Form.Text className="text-muted">
                                    {formik.errors.address}
                            </Form.Text>
                             ) : null
                  }
            </Form.Group>


            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>City</Form.Label>
                <Form.Control                             
                            name="city"
                            type="text"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            />

                    {
                      formik.touched.city && formik.errors.city ? (
                        <Form.Text className="text-muted">
                            {formik.errors.city}
                      </Form.Text>
                      ) : null
                     } 
                </Form.Group>

                <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Province</Form.Label>
                <Form.Control                          
                            name="province"
                            type="text"
                            value={formik.values.province}
                            onChange={formik.handleChange}
                            />
                    {
                      formik.touched.province && formik.errors.province ? (
                        <Form.Text className="text-muted">
                            {formik.errors.province}
                      </Form.Text>
                      ) : null
                    }
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPostelCode">
                <Form.Label >Postal Code</Form.Label>
                <Form.Control 
                            name="postalCode"
                            value={formik.values.postalCode}
                            onChange={formik.handleChange}
                            />
                    {
                      formik.touched.postalCode && formik.errors.postalCode ? (
                        <Form.Text className="text-muted">
                            {formik.errors.postalCode}
                      </Form.Text>
                      ) : null
                    }
                </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Example textarea</Form.Label>
                <Form.Control               
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                as="textarea" 
                rows={3} />

                {
                      formik.touched.description && formik.errors.description ? (
                        <Form.Text className="text-muted">
                            {formik.errors.description}
                      </Form.Text>
                      ) : null
                }
            </Form.Group>
            
            <Form.Group className="mb-3" id="formGridCheckbox">
                <Form.Check 
                    name="isReady"
                    value={formik.values.isReady}
                    onChange={formik.handleChange}
                    type="checkbox" 
                    label="Is the project ready to start?" />
            </Form.Group>

            <Button variant="primary" type="submit">
                Submit
            </Button>
            </Form>
        </div>
    </div>
    )}
    </>
    );
}

/*
            <Form.Group className="mb-3" id="formGridCheckbox">
                <Form.Check 
                    id="isReady"
                    name="isReady"
                    value={formik.values.isReady}
                    onChange={formik.handleChange}
                    type="checkbox" 
                    label="Is the project ready to start?" />
            </Form.Group>
*/