const stairImages = [
    {
      original: '../gallary/stairs/stairs-1.jpg',
      thumbnail: '../gallary/stairs/stairs-1.jpg',
      originalHeight: '500px',
      
    },
    {
        original: '../gallary/stairs/stairs-2.jpg',
        thumbnail: '../gallary/stairs/stairs-2.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-3.jpg',
        thumbnail: '../gallary/stairs/stairs-3.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-4.jpg',
        thumbnail: '../gallary/stairs/stairs-4.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-5.jpg',
        thumbnail: '../gallary/stairs/stairs-5.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-6.jpg',
        thumbnail: '../gallary/stairs/stairs-6.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-7.jpg',
        thumbnail: '../gallary/stairs/stairs-7.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-8.jpg',
        thumbnail: '../gallary/stairs/stairs-8.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-9.jpg',
        thumbnail: '../gallary/stairs/stairs-9.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-10.jpg',
        thumbnail: '../gallary/stairs/stairs-10.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-11.jpg',
        thumbnail: '../gallary/stairs/stairs-11.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-12.jpg',
        thumbnail: '../gallary/stairs/stairs-12.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-13.jpg',
        thumbnail: '../gallary/stairs/stairs-13.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-14.jpg',
        thumbnail: '../gallary/stairs/stairs-14.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-15.jpg',
        thumbnail: '../gallary/stairs/stairs-15.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-16.jpg',
        thumbnail: '../gallary/stairs/stairs-16.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-17.jpg',
        thumbnail: '../gallary/stairs/stairs-17.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-18.jpg',
        thumbnail: '../gallary/stairs/stairs-18.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-19.jpg',
        thumbnail: '../gallary/stairs/stairs-19.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-20.jpg',
        thumbnail: '../gallary/stairs/stairs-20.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-21.jpg',
        thumbnail: '../gallary/stairs/stairs-21.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-22.jpg',
        thumbnail: '../gallary/stairs/stairs-22.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-23.jpg',
        thumbnail: '../gallary/stairs/stairs-23.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-24.jpg',
        thumbnail: '../gallary/stairs/stairs-24.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-25.jpg',
        thumbnail: '../gallary/stairs/stairs-25.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-26.jpg',
        thumbnail: '../gallary/stairs/stairs-26.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-27.jpg',
        thumbnail: '../gallary/stairs/stairs-27.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-28.jpg',
        thumbnail: '../gallary/stairs/stairs-28.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-29.jpg',
        thumbnail: '../gallary/stairs/stairs-29.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-30.jpg',
        thumbnail: '../gallary/stairs/stairs-30.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-31.jpg',
        thumbnail: '../gallary/stairs/stairs-31.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-32.jpg',
        thumbnail: '../gallary/stairs/stairs-32.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-33.jpg',
        thumbnail: '../gallary/stairs/stairs-33.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-34.jpg',
        thumbnail: '../gallary/stairs/stairs-34.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-36.jpg',
        thumbnail: '../gallary/stairs/stairs-36.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-37.jpg',
        thumbnail: '../gallary/stairs/stairs-37.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-38.jpg',
        thumbnail: '../gallary/stairs/stairs-38.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-39.jpg',
        thumbnail: '../gallary/stairs/stairs-39.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-40.jpg',
        thumbnail: '../gallary/stairs/stairs-40.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-41.jpg',
        thumbnail: '../gallary/stairs/stairs-41.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-42.jpg',
        thumbnail: '../gallary/stairs/stairs-42.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-43.jpg',
        thumbnail: '../gallary/stairs/stairs-43.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-44.jpg',
        thumbnail: '../gallary/stairs/stairs-44.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-45.jpg',
        thumbnail: '../gallary/stairs/stairs-45.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-46.jpg',
        thumbnail: '../gallary/stairs/stairs-46.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-47.jpg',
        thumbnail: '../gallary/stairs/stairs-47.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-48.jpg',
        thumbnail: '../gallary/stairs/stairs-48.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-49.jpg',
        thumbnail: '../gallary/stairs/stairs-49.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-50.jpg',
        thumbnail: '../gallary/stairs/stairs-50.jpg',
        originalHeight: '500px',
    },
    {
        original: '../gallary/stairs/stairs-51.jpg',
        thumbnail: '../gallary/stairs/stairs-51.jpg',
        originalHeight: '500px',
    },
  ];

  export default stairImages;