import React from 'react';
import './contact.css';
import { Alert, Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';



export default function Contact() {
    const [complated, setComplated] = useState(false);
    const [error, setError] = useState(null);


    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phoneNumber: '',
            description: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Name is required"),
            email: Yup.string().email("invalid email address").required("Email is required"),
            phoneNumber: Yup.string().matches(/^[0-9]{10}$/, "The phone number must be 10 digits").required("Phone number is required"),
            description: Yup.string().min(0).max(400, "Must be at most 400 characters"),
        }), 
        onSubmit: values => {
            // sent to backend api 
            console.log(values);
            fetch('https://api-precisionfix.azurewebsites.net/contact/us', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(values),
            }).then(res => {
                if(res.status === 200) {
                    setComplated(true);
                } else {
                    throw new Error('form not valid');
                }
            }).catch(err => {
                console.log('Error submitting form', err);
                setError(err);
            });
        }
    });

    if(complated) {
      return (
        <div id="alert">
        <Alert variant="success">
          <Alert.Heading>Form Successfully submitted</Alert.Heading>
          <p>Thank you for taking the first! Your form has been submitted, and we appreciate your interest. Out team will review your submisison and get in touch with you soon</p>
          <hr />
        <div className="d-flex justify-content-end">
        <a href={/home/}><Button variant="outline-success">
            get back to homepage
        </Button></a>
        </div>
        </Alert>
        </div>
      );
    }
    if(error) {
        console.log("Error", error);
    }

    return (
    <>
    <div id="container-wrapper">
    <span id="contact-headline"><h1>Contact Us</h1></span>
      
    <Container id="container">
      <Row>
        <Col>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control   
                        name="name"
                        type="text" 
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        placeholder="Enter your name" />
                         {
                      formik.touched.name && formik.errors.name ? (
                        <Form.Text className="text-muted">
                            {formik.errors.name}
                      </Form.Text>
                      ) : null
                        }
            </Form.Group>

            <Form.Group controlId="phoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control 
                  name="phoneNumber"
                  type="phone" 
                  placeholder="Enter phone number"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                 />
                  {
                      formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                        <Form.Text className="text-muted">
                            {formik.errors.phoneNumber}
                      </Form.Text>
                      ) : null
                  }
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control 
                        name="email"
                        type="email" 
                        placeholder="Enter email" 
                        value={formik.values.email}
                        onChange={formik.handleChange}/>
                         {
                      formik.touched.email && formik.errors.email ? (
                        <Form.Text className="text-muted">
                            {formik.errors.email}
                      </Form.Text>
                      ) : null
                        }
            </Form.Group>

            <Form.Group controlId="description">
              <Form.Label>Message</Form.Label>
              <Form.Control
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              as="textarea" 
              rows={3} />
              {
                      formik.touched.description && formik.errors.description ? (
                        <Form.Text className="text-muted">
                            {formik.errors.description}
                      </Form.Text>
                      ) : null
            }
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>

      <div id="contact-info">
      <span className="contact-details">
      <h5><strong><font size="3" color="lightblue">Call Us</font></strong></h5>
      <h5>6477415008</h5>
      </span>
      <span className="contact-details"> 
      <h5><strong><font size="3" color="lightblue">Email</font></strong></h5>
      <h5>Support@PrecisionFix.com</h5>
      </span>
      </div>
    </div>
   
    </>)
}