const floorImages = [
    {
      original: '../gallary/floor/floor-1.jpg',
      thumbnail: '../gallary/floor/floor-1.jpg',
      originalHeight: '500px',
      
    },
    {
        original: '../gallary/floor/floor-2.jpg',
        thumbnail: '../gallary/floor/floor-2.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-3.jpg',
        thumbnail: '../gallary/floor/floor-3.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-4.jpg',
        thumbnail: '../gallary/floor/floor-4.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-5.jpg',
        thumbnail: '../gallary/floor/floor-5.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-6.jpg',
        thumbnail: '../gallary/floor/floor-6.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-7.jpg',
        thumbnail: '../gallary/floor/floor-7.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-8.jpg',
        thumbnail: '../gallary/floor/floor-8.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-9.jpg',
        thumbnail: '../gallary/floor/floor-9.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-10.jpg',
        thumbnail: '../gallary/floor/floor-10.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-11.jpg',
        thumbnail: '../gallary/floor/floor-11.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-12.jpg',
        thumbnail: '../gallary/floor/floor-12.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-13.jpg',
        thumbnail: '../gallary/floor/floor-13.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-14.jpg',
        thumbnail: '../gallary/floor/floor-14.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-15.jpg',
        thumbnail: '../gallary/floor/floor-15.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-16.jpg',
        thumbnail: '../gallary/floor/floor-16.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-17.jpg',
        thumbnail: '../gallary/floor/floor-17.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-18.jpg',
        thumbnail: '../gallary/floor/floor-18.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-19.jpg',
        thumbnail: '../gallary/floor/floor-19.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-20.jpg',
        thumbnail: '../gallary/floor/floor-20.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-21.jpg',
        thumbnail: '../gallary/floor/floor-21.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-22.jpg',
        thumbnail: '../gallary/floor/floor-22.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-23.jpg',
        thumbnail: '../gallary/floor/floor-23.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-24.jpg',
        thumbnail: '../gallary/floor/floor-24.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-25.jpg',
        thumbnail: '../gallary/floor/floor-25.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-26.jpg',
        thumbnail: '../gallary/floor/floor-26.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-27.jpg',
        thumbnail: '../gallary/floor/floor-27.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-28.jpg',
        thumbnail: '../gallary/floor/floor-28.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-29.jpg',
        thumbnail: '../gallary/floor/floor-29.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-30.jpg',
        thumbnail: '../gallary/floor/floor-30.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-31.jpg',
        thumbnail: '../gallary/floor/floor-31.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-32.jpg',
        thumbnail: '../gallary/floor/floor-32.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-33.jpg',
        thumbnail: '../gallary/floor/floor-33.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-34.jpg',
        thumbnail: '../gallary/floor/floor-34.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-35.jpg',
        thumbnail: '../gallary/floor/floor-35.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-36.jpg',
        thumbnail: '../gallary/floor/floor-36.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-37.jpg',
        thumbnail: '../gallary/floor/floor-37.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-38.jpg',
        thumbnail: '../gallary/floor/floor-38.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-38.jpg',
        thumbnail: '../gallary/floor/floor-38.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-39.jpg',
        thumbnail: '../gallary/floor/floor-39.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-40.jpg',
        thumbnail: '../gallary/floor/floor-40.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-41.jpg',
        thumbnail: '../gallary/floor/floor-41.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-42.jpg',
        thumbnail: '../gallary/floor/floor-42.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-43.jpg',
        thumbnail: '../gallary/floor/floor-43.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-44.jpg',
        thumbnail: '../gallary/floor/floor-44.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-45.jpg',
        thumbnail: '../gallary/floor/floor-45.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-46.jpg',
        thumbnail: '../gallary/floor/floor-46.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-47.jpg',
        thumbnail: '../gallary/floor/floor-47.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-48.jpg',
        thumbnail: '../gallary/floor/floor-48.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-49.jpg',
        thumbnail: '../gallary/floor/floor-49.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-50.jpg',
        thumbnail: '../gallary/floor/floor-50.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-51.jpg',
        thumbnail: '../gallary/floor/floor-51.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-52.jpg',
        thumbnail: '../gallary/floor/floor-52.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-53.jpg',
        thumbnail: '../gallary/floor/floor-53.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-54.jpg',
        thumbnail: '../gallary/floor/floor-54.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-55.jpg',
        thumbnail: '../gallary/floor/floor-55.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-56.jpg',
        thumbnail: '../gallary/floor/floor-56.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-57.jpg',
        thumbnail: '../gallary/floor/floor-57.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-58.jpg',
        thumbnail: '../gallary/floor/floor-58.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-59.jpg',
        thumbnail: '../gallary/floor/floor-59.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-60.jpg',
        thumbnail: '../gallary/floor/floor-60.jpg',
        originalHeight: '500px',
        
    },
    {
        original: '../gallary/floor/floor-61.jpg',
        thumbnail: '../gallary/floor/floor-61.jpg',
        originalHeight: '500px',
        
    },

  ];
  
  export default floorImages;