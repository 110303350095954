import React from 'react';
import {useState} from 'react';
import './Stairs.css';
import './floor.css'
import { Form, Button, Row, Col, CloseButton} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ImageGallery from 'react-image-gallery';
import floorImages from './Image-floor';


export default function Floor() {

   

    const [ isOpen, setIsOpen ] = useState(false);

    const [complated, setComplated] = useState(false);
    const [error, setError] = useState(null);
    const formik = useFormik({
        initialValues: { 
            name: '',
            email: '',
            phoneNumber: '',
            address: '',
            city: '',
            province: '',
            postalCode: '',
            floorType: '',
            description: '',
            isReady: false
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Name is required"),
            email: Yup.string().email("invalid email address").required("Email is required"),
            phoneNumber: Yup.string().matches(/^[0-9]{10}$/, "The phone number must be 10 digits").required("Phone number is required"),
            address: Yup.string().min(0).max(135).required("Address is required"),
            city: Yup.string().min(0).max(100, "Must be at most 100 characters").required("City is required"),
            province: Yup.string().required("Province is required"),
            postalCode: Yup.string().required("Postal code is required"),
            floorType: Yup.string().required(),
            description: Yup.string().min(0).max(400, "Must be at most 400 characters"),
        }), 
        onSubmit: values => {
            // sent to backend api 
            console.log(values);
            fetch('https://api-precisionfix.azurewebsites.net/floor/estimate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(values),
            }).then(res => {
                if(res.status === 200) {
                    setComplated(true);
                } else {
                    throw new Error('form not valid');
                }
            }).catch(err => {
                console.log('Error submitting form', err);
                setError(err);
            });
        }
    });

    if(complated) {
        return (
            <div id="alert">
            <Alert variant="success">
              <Alert.Heading>Form Successfully submitted</Alert.Heading>
              <p>Thank you for taking the first! Your form has been submitted, and we appreciate your interest. Out team will review your submisison and get in touch with you soon</p>
              <hr />
            <div className="d-flex justify-content-end">
            <a href={/home/}><Button variant="outline-success">
                get back to homepage
            </Button></a>
            </div>
            </Alert>
            </div>
          );
    }
    if(error) {
        console.log("Error", error);
    }
    return (
    <>
    <div id="floor-nav-bg">
        <div id="floor-intro">
            <h2>Transform Your Home with Professional Painting Services</h2>
            <p>With our expert floor installation services, we can help you create a stunning foundation that sets the stage for a beautiful and functional space. Our team of skilled professionals brings years of experience, a commitment to professionalism, and a dedication to delivering top-quality results. Whether you're envisioning the timeless elegance of hardwood, the durability of laminate, or the versatility of tile, we have the expertise and craftsmanship to bring your vision to life. Trust us to handle every aspect of your floor installation project with precision, attention to detail, and a relentless pursuit of excellence. Get ready to step onto floors that not only impress but also stand the test of time. Experience the difference with Precision<strong>Fix</strong></p>
            <button onClick={() => { setIsOpen(true)}}>Schedule an estimate</button>
        </div>
    </div>


    <div id="floor-solutions">
        <h3>Elevate Your Space with Premium Flooring Solutions</h3>
        <p id="floor-solu-p">At PrecisionFix, we offer a wide range of flooring services to enhance the beauty and functionality of your space. Our team of skilled professionals is committed to delivering exceptional results and transforming your floors into stunning focal points. Here's an overview of the flooring services we provide:</p>
        <div className="floor-solution">
            <span className="flex-left">
            <img src={require('./public/hardwood.jpg')} alt="hardwood floor"/>
            </span>
            <span className="flex-right">
            <h2>Hardwood Flooring</h2>
            <p>Experience the timeless elegance and warmth of hardwood flooring. Our expert installers will carefully select and install high-quality hardwood materials, creating a durable and beautiful foundation for your space. From traditional oak to exotic species, we offer a variety of options to suit your style and preferences.</p>
            </span>
        </div>
        <div className="floor-solution">
            <span className="flex-left">
            <img src={require('./public/laminate.jpg')} alt="hardwood floor"/>
            </span>
            <span className="flex-right">
            <h2>Laminate Flooring </h2>
            <p>Achieve the look of real wood or tile without compromising on durability and affordability. Our laminate flooring options provide versatility and easy maintenance while replicating the natural textures and patterns you desire. Let us help you choose from a wide range of finishes to create a stylish and resilient flooring solution.</p>
            </span>
        </div>
        <div className="floor-solution">
            <span className="flex-left">
            <img src={require('./public/tile.jpg')} alt="hardwood floor"/>
            </span>
            <span className="flex-right">
            <h2>Tile Flooring </h2>
            <p>Add a touch of elegance and functionality with our tile flooring solutions. Whether you prefer ceramic, porcelain, or natural stone tiles, we have the expertise to create stunning patterns, designs, and layouts. Our meticulous installation process ensures precise alignment, resulting in beautiful and long-lasting tiled floors.</p>
            </span>
        </div>
        <div className="floor-solution">
            <span className="flex-left">
            <img src={require('./public/vinyl.jpg')} alt="hardwood floor"/>
            </span>
            <span className="flex-right">
            <h2>Vinyl Flooring </h2>
            <p>Experience the ultimate combination of style, durability, and affordability with our vinyl flooring options. With an extensive selection of patterns and designs, including luxury vinyl plank and vinyl tile, we can create a versatile and low-maintenance flooring solution that suits any room in your home.</p>
            </span>
        </div>
        <div id="additionnal-service">
            <h2>Additional Services</h2>
            <ul>
                <li><strong>Flooring Removal and Disposal: </strong>We handle the safe removal and disposal of old flooring materials, ensuring a clean and hassle-free transition.</li>
                <li><strong>Subfloor Preparation: </strong>Our team will assess and prepare the subfloor to ensure a smooth and stable surface for the new flooring installation.</li>
                <li><strong>Flooring Repairs: </strong>We offer expert repair services for damaged or worn-out flooring, including patching, refinishing, and resealing.</li>
                <li><strong>Custom Design and Layout: </strong>If you have a specific design or layout in mind, we can work with you to bring your vision to life, creating unique patterns and combinations.</li>     
            </ul>
        </div>
    </div>

    <div className="schedule-estimate"> 
        <h5>Trust P
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            recisionFix for all your flooring needs. Contact us today to schedule a consultation and explore the possibilities of elevating your space with our premium flooring solutions.</h5>
        <button onClick={() => setIsOpen(true)}>Schedule an Estimate</button>
    </div>

    <div className="home-gallery">
            <ImageGallery items={floorImages} />
    </div>

    <div id="service-process">
        <div id="process-intro">
            <h1>Transforming Your Space One Step at a Time</h1>
            <p>At PrecisionFix, we follow a systematic approach to ensure a seamless and professional flooring installation experience. Our step-by-step process, combined with our team's attention to detail and expertise, guarantees outstanding results. Here's an overview of our flooring service process:</p>
        </div>
        <div className="service-process">
            <h4>Step 1: Consultation and Assessment </h4>
            <p>We begin with a thorough consultation to understand your flooring needs and assess your space. Our experts will discuss your vision, take precise measurements, and provide professional recommendations based on your preferences, budget, and lifestyle.</p>
        </div>
        <div className="service-process"> 
            <h4>Step 2: Material Selection and Design </h4>
            <p>Once we have gathered the necessary information, we guide you through the selection process, helping you choose the ideal flooring material, color, and style. We'll provide samples and assist you in finding the perfect flooring that matches your aesthetic vision and functional requirements.</p>
        </div>
        <div className="service-process">
            <h4>Step 3: Preparation and Subfloor Inspection </h4>
            <p>Before installation, we meticulously prepare the subfloor, ensuring it is clean, level, and free from any imperfections. Our team conducts a thorough inspection to identify and address any issues that may affect the installation process or the longevity of the new flooring.</p>
        </div>
        <div className="service-process">
            <h4>Step 4: Flooring Installation </h4>
            <p>With precise measurements and careful planning, we proceed to install your chosen flooring material. Our experienced installers use professional-grade tools and techniques to ensure accurate alignment, secure adhesion, and a flawless finish. We pay close attention to details, such as transitions, corners, and edges, to achieve a seamless and aesthetically pleasing result.</p>
        </div>
        <div className="service-process">
            <h4>Step 5: Finishing Touches and Clean-Up </h4>
            <p>Once the flooring is installed, we meticulously complete any necessary finishing touches, such as baseboard installation or trim work, to achieve a polished and cohesive look. Our team then conducts a thorough clean-up, removing any debris or remnants from the installation process, leaving your space tidy and ready to enjoy.</p>
        </div>
        <div className="service-process">
            <h4>Step 6: Final Inspection and Client Satisfaction </h4>
            <p>Before we consider the project complete, our team performs a comprehensive final inspection to ensure every aspect of the installation meets our high standards. We invite you for a walkthrough to ensure your satisfaction and address any concerns promptly. Your happiness and delight with our work are our top priorities.</p>
        </div>
    </div>

    { isOpen && (
    <div className="container-form">
        {
            error ? <h1>Error</h1> : null
        }
    
    <div id="stair-form">
        <Form onSubmit={formik.handleSubmit}>
            <CloseButton id="close_button" onClick={() => setIsOpen(false)}/>
            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridFirstName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control 
                        name="name"
                        type="text" 
                        placeholder="Enter name" 
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />
                  {
                      formik.touched.name && formik.errors.name ? (
                        <Form.Text className="text-muted">
                            {formik.errors.name}
                      </Form.Text>
                      ) : null
                  }
        </Form.Group>
               
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control 
                        name="email"
                        type="email" 
                        placeholder="Enter email" 
                        value={formik.values.email}
                        onChange={formik.handleChange}
                />
                  {
                      formik.touched.email && formik.errors.email ? (
                        <Form.Text className="text-muted">
                            {formik.errors.email}
                      </Form.Text>
                      ) : null
                  }
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPhoneNumber">
                <Form.Label>Phone number</Form.Label>
                <Form.Control 
                        name="phoneNumber"
                        type="phone" 
                        placeholder="Enter phone number"
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        />
                          {
                      formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                        <Form.Text className="text-muted">
                            {formik.errors.phoneNumber}
                      </Form.Text>
                      ) : null
                  }
                </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Address</Form.Label>
                <Form.Control 
                        name="address"
                        placeholder="1234 Main St" 
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        />
                          {
                            formik.touched.address && formik.errors.address ? (
                                <Form.Text className="text-muted">
                                    {formik.errors.address}
                            </Form.Text>
                             ) : null
                  }
            </Form.Group>


            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>City</Form.Label>
                <Form.Control 
                            name="city"
                            type="text"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            />

                    {
                      formik.touched.city && formik.errors.city ? (
                        <Form.Text className="text-muted">
                            {formik.errors.city}
                      </Form.Text>
                      ) : null
                     } 
                </Form.Group>

                <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Province</Form.Label>
                <Form.Control
                            name="province"
                            type="text"
                            value={formik.values.province}
                            onChange={formik.handleChange}
                            />
                    {
                      formik.touched.province && formik.errors.province ? (
                        <Form.Text className="text-muted">
                            {formik.errors.province}
                      </Form.Text>
                      ) : null
                    }
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPostelCode">
                <Form.Label >Postal Code</Form.Label>
                <Form.Control 
                            name="postalCode"
                            value={formik.values.postalCode}
                            onChange={formik.handleChange}
                            />
                    {
                      formik.touched.postalCode && formik.errors.postalCode ? (
                        <Form.Text className="text-muted">
                            {formik.errors.postalCode}
                      </Form.Text>
                      ) : null
                    }
                </Form.Group>
            </Row>

            <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Floor Type</Form.Label>
                <Form.Select 
                        name="floorType"
                        value={formik.values.floorType}
                        onChange={formik.handleChange}
                        defaultValue="Choose..."
                        >
                    <option>Choose...</option>
                    <option>Solid Hardwood Flooring</option>
                    <option>Engineered Hardwood Flooring</option>
                    <option>Laminate Flooring</option>
                    <option>Vinyl Flooring</option>
                    <option>Parquet Flooring</option>
                    <option>Other</option>
                </Form.Select>

            </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Example textarea</Form.Label>
                <Form.Control 
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                as="textarea" 
                rows={3} />

                {
                      formik.touched.description && formik.errors.description ? (
                        <Form.Text className="text-muted">
                            {formik.errors.description}
                      </Form.Text>
                      ) : null
                }
            </Form.Group>
            
            <Form.Group className="mb-3" id="formGridCheckbox">
                <Form.Check 
                    name="isReady"
                    value={formik.values.isReady}
                    onChange={formik.handleChange}
                    type="checkbox" 
                    label="Is the project ready to start?" />
            </Form.Group>

            <Button variant="primary" type="submit">
                Submit
            </Button>
            </Form>
        </div>
    </div>
    )}
    </>
    );
}

/*
            <Form.Group className="mb-3" id="formGridCheckbox">
                <Form.Check 
                    id="isReady"
                    name="isReady"
                    value={formik.values.isReady}
                    onChange={formik.handleChange}
                    type="checkbox" 
                    label="Is the project ready to start?" />
            </Form.Group>
*/