
import './App.css';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './Home';
import Services from './Services';
import About from './About';
import Navigation from './Nav';
import Contact from './Contact';
import Stairs from './Stairs';
import Footer from './Footer';
import Floor from './Floor';
import Paint from './Paint';

function App() {
 
  return(
    <div className="App">
     <Switch>
     <Route path='/service/stairs'>
          <Navigation />
          <Stairs />
          <Footer />
      </Route>
     <Route path='/service/paint'>
          <Navigation />
          <Paint />
          <Footer />
       </Route>
     <Route path='/service/floor'>
          <Navigation />
          <Floor />
          <Footer />
       </Route>
       <Route path='/about'>
          <Navigation />
          <About />
          <Footer />
       </Route>
       <Route path='/services'> 
          <Navigation /> 
          <Services />
       </Route>
       <Route path='/contact'>
         <Navigation />
         <Contact />
         <Footer />
       </Route>
       <Route path='/'> 
          <Navigation />
          <Home />
          <Footer />
       </Route>
       
     </Switch>
    </div>
  );
}

export default App;
