import React from 'react';
import {useState} from 'react';
import './Stairs.css';
import { Alert, Form, Button, Row, Col, CloseButton} from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ImageGallery from 'react-image-gallery';
import stairImages from './image-stairs';


export default function Stairs() {

    const [error, setError] = useState(null);
    const [ isOpen, setIsOpen ] = useState(false);

    const [completed, setCompleted] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phoneNumber: '',
            address: '',
            city: '',
            province: '',
            postalCode: '',
            stairType: '',
            stairStyle: '',
            description: '',
            isReady: false
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Name is required"),
            email: Yup.string().email("invalid email address").required("Email is required"),
            phoneNumber: Yup.string().matches(/^[0-9]{10}$/, "The phone number must be 10 digits").required("Phone number is required"),
            address: Yup.string().min(0).max(135).required("Address is required"),
            city: Yup.string().min(0).max(100, "Must be at most 100 characters").required("City is required"),
            province: Yup.string().required("Province is required"),
            postalCode: Yup.string().required("Postal code is required"),
            stairType: Yup.string().required(),
            stairStyle: Yup.string().required(),
            description: Yup.string().min(0).max(400, "Must be at most 400 characters"),
        }), 
        onSubmit: values => {
            // sent to backend api 
            console.log(values);
            fetch('https://api-precisionfix.azurewebsites.net/stair/estimate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(values),
            }).then(res => {
                if(res.status === 200) {
                    setCompleted(true);
                } else {
                    throw new Error('form not valid');
                }
            }).catch(err => {
                console.log('Error submitting form', err);
                setError(err);
            });
        }
    });

    if(completed) {
        return (
            <div id="alert">
            <Alert variant="success">
              <Alert.Heading>Form Successfully submitted</Alert.Heading>
              <p>Thank you for taking the first! Your form has been submitted, and we appreciate your interest. Out team will review your submisison and get in touch with you soon</p>
              <hr />
            <div className="d-flex justify-content-end">
            <a href={/home/}><Button variant="outline-success">
                get back to homepage
            </Button></a>
            </div>
            </Alert>
            </div>
          );
    }
    if(error) {
        console.log("Error", error);
    }
    return (
    <>
    <div id="stairs-wallpaper-bg">
        <div id="floor-intro">
            <h2>Expert Stair Installation Services</h2>
            <p>Welcome to PrecisionFix, your trusted source for professional stair installation services. We specialize in creating beautiful, functional, and safe staircases that enhance the aesthetics and functionality of your space. With years of experience and a team of skilled craftsmen, we are committed to delivering exceptional results that exceed your expectations.</p>
            <button onClick={() => { setIsOpen(true)}}>Schedule an estimate</button>
        </div>
    </div>


    <div className="stair-expectation">
        <h2>When you select PrecisionFix for your stair installation project, you can expect:</h2> 
        <ul>
            <li>Skilled craftsmen with years of experience in stair installation, delivering unparalleled expertise and craftsmanship.</li>
            <li>Personalized design solutions tailored to your unique needs and preferences, ensuring your staircase is a true reflection of your style.</li>
            <li>The use of high-quality materials that are durable and visually appealing, providing long-lasting beauty and functionality.</li>
            <li>Adherence to safety regulations and building codes, prioritizing the well-being of you and your family.</li>
            <li>Exceptional attention to detail and precision throughout the installation process, guaranteeing a flawless and seamless result.</li>
            <li>Timely project completion, allowing you to enjoy your new staircase promptly.</li>
            <li>Dedicated customer support and post</li>
        </ul>
    </div>

    <div className="home-gallery">
            <ImageGallery items={stairImages} />
    </div>

    <div id="service-process">
        <div id="process-intro">
            <h1>Initial Consultation:</h1>
            <p>At PrecisionFix
                , we follow a meticulous painting process to ensure exceptional results and a smooth experience for our clients. Our step-by-step approach, combined with our team's attention to detail and professionalism, guarantees a flawless and long-lasting finish. Here's an overview of our painting process:</p>
        </div>
        <div className="service-process">
            <h4>Initial Consultation</h4>
            <p>We believe in a collaborative approach to create the perfect staircase for your needs. Our process begins with a thorough consultation where our experts will listen attentively to your requirements, design preferences, and any specific challenges related to your space. By understanding your vision, we can tailor our services to meet your unique needs.</p>
        </div>
        <div className="service-process"> 
            <h4>Customized Design:</h4>
            <p>Once we have gathered all the necessary information, our experienced designers will work closely with you to develop a customized stair design. Taking into account your style preferences, available space, and architectural features, we'll create a visually appealing and functional staircase that complements your overall interior design.</p>
        </div>
        <div className="service-process">
            <h4>Material Selection:</h4>
            <p>We offer a wide selection of premium materials to choose from, ensuring you find the perfect fit for your staircase. Whether you desire the warmth of hardwood, the versatility of laminate, the plushness of carpet, or the elegance of wrought iron, we have the expertise to guide you through the selection process. We'll discuss the benefits, maintenance requirements, and visual appeal of each material, helping you make an informed decision.</p>
        </div>
        <div className="service-process">
            <h4>Professional Installation:</h4>
            <p>Our team of skilled craftsmen is equipped with the expertise and tools necessary to execute the installation with precision and attention to detail. We begin by removing any existing stairs, ensuring a clean slate for your new staircase. Our professionals then install the new components, carefully constructing each step, railing, and baluster to ensure a secure and stable structure.</p>
        </div>
        <div className="service-process">
            <h4>Safety and Compliance:</h4>
            <p>Safety is of utmost importance to us. Throughout the installation process, we strictly adhere to all relevant safety regulations and building codes. We pay close attention to factors such as proper railing height, secure baluster installation, slip-resistant surfaces, and appropriate step dimensions to create a staircase that not only looks stunning but also provides a safe environment for you and your loved ones.</p>
        </div>
        <div className="service-process">
            <h4>Finishing Touches:</h4>
            <p>We understand the significance of the final details in completing the look of your staircase. Our team offers a range of options for handrails, balusters, and newel posts, allowing you to choose the perfect style to enhance your staircase's overall aesthetic. Whether you prefer a classic, contemporary, or custom design, we have the expertise to deliver exceptional finishing touches that reflect your personal taste.</p>
        </div>
        <div className="service-process">
            <h4>Client Satisfaction:</h4>
            <p>Your satisfaction is our ultimate goal. Once the installation is complete, we conduct a comprehensive walkthrough with you, ensuring every aspect of the project meets your expectations. We value your feedback and take prompt action to address any concerns, ensuring that you are delighted with the final outcome.</p>
        </div>
    </div>

    { isOpen && (
    <div className="container-form">
        {
            error ? <h1>Error</h1> : null
        }

        <div id="stair-form">
        <Form onSubmit={formik.handleSubmit}>
            <CloseButton id="close_button" onClick={() => setIsOpen(false)}/>
            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridFirstName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control 
                        id="name"
                        name="name"
                        type="text" 
                        placeholder="Enter name" 
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />
                  {
                      formik.touched.name && formik.errors.name ? (
                        <Form.Text className="text-muted">
                            {formik.errors.name}
                      </Form.Text>
                      ) : null
                  }
        </Form.Group>
               
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control 
                        name="email"
                        type="email" 
                        placeholder="Enter email" 
                        value={formik.values.email}
                        onChange={formik.handleChange}
                />
                  {
                      formik.touched.email && formik.errors.email ? (
                        <Form.Text className="text-muted">
                            {formik.errors.email}
                      </Form.Text>
                      ) : null
                  }
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPhoneNumber">
                <Form.Label>Phone number</Form.Label>
                <Form.Control 
                        name="phoneNumber"
                        type="phone" 
                        placeholder="Enter phone number"
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        />
                          {
                      formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                        <Form.Text className="text-muted">
                            {formik.errors.phoneNumber}
                      </Form.Text>
                      ) : null
                  }
                </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>Address</Form.Label>
                <Form.Control 
                        name="address"
                        placeholder="1234 Main St" 
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        />
                          {
                            formik.touched.address && formik.errors.address ? (
                                <Form.Text className="text-muted">
                                    {formik.errors.address}
                            </Form.Text>
                             ) : null
                  }
            </Form.Group>


            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>City</Form.Label>
                <Form.Control 
                            name="city"
                            type="text"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            />

                    {
                      formik.touched.city && formik.errors.city ? (
                        <Form.Text className="text-muted">
                            {formik.errors.city}
                      </Form.Text>
                      ) : null
                     } 
                </Form.Group>

                <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Province</Form.Label>
                <Form.Control
                            name="province"
                            type="text"
                            value={formik.values.province}
                            onChange={formik.handleChange}
                            />
                    {
                      formik.touched.province && formik.errors.province ? (
                        <Form.Text className="text-muted">
                            {formik.errors.province}
                      </Form.Text>
                      ) : null
                    }
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPostelCode">
                <Form.Label >Postal Code</Form.Label>
                <Form.Control 
                            name="postalCode"
                            value={formik.values.postalCode}
                            onChange={formik.handleChange}
                            />
                    {
                      formik.touched.postalCode && formik.errors.postalCode ? (
                        <Form.Text className="text-muted">
                            {formik.errors.postalCode}
                      </Form.Text>
                      ) : null
                    }
                </Form.Group>
            </Row>

            <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Stairs Type</Form.Label>
                <Form.Select 
                        name="stairType"
                        value={formik.values.stairType}
                        onChange={formik.handleChange}
                        defaultValue="Choose..."
                        >
                    <option>Choose...</option>
                    <option>Straight</option>
                    <option>Curved</option>
                    <option>Spiral</option>
                    <option>Other</option>
                </Form.Select>

            </Form.Group>

            <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Stair Style</Form.Label>
                <Form.Select 
                        defaultValue="Choose..."
                        name="stairStyle"
                        value={formik.values.stairStyle}
                        onChange={formik.handleChange}>
                    <option>Choose...</option>
                    <option>Painted</option>
                    <option>stained</option>
                </Form.Select>
            </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Example textarea</Form.Label>
                <Form.Control 
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                as="textarea" 
                rows={3} />

                {
                      formik.touched.description && formik.errors.description ? (
                        <Form.Text className="text-muted">
                            {formik.errors.description}
                      </Form.Text>
                      ) : null
                }
            </Form.Group>
            
            <Form.Group className="mb-3" id="formGridCheckbox">
                <Form.Check 
                    name="isReady"
                    value={formik.values.isReady}
                    onChange={formik.handleChange}
                    type="checkbox" 
                    label="Is the project ready to start?" />
            </Form.Group>

            <Button variant="primary" type="submit">
                Submit
            </Button>
            </Form>
        </div>
    </div>
    )}
    </>
    );
}

/*
            <Form.Group className="mb-3" id="formGridCheckbox">
                <Form.Check 
                    id="isReady"
                    name="isReady"
                    value={formik.values.isReady}
                    onChange={formik.handleChange}
                    type="checkbox" 
                    label="Is the project ready to start?" />
            </Form.Group>
*/