import React from 'react';
import "./about.css";

export default function About() {
    return (
    <>
        <div id="about-container"> 
        <div className="about-us">
        <h1>About Us</h1>
        <p>We are the Precision<strong><strong>Fix</strong></strong>, and we’ve been providing easier and more convenient flooring, stairs and painting services for home and business owners in GTA over a decade. We are creative, organized, detail oriented and committed to provide the highest quality service for customers that exceed their expectation. We guarantee to working together to provide consistently thorough and the exceptional service to highest standard on punctual manner. </p>
        </div>


        <div className="value"> 
            <h1>Our Values</h1> 
            <p>At Precision<strong><strong>Fix</strong></strong>, we are guided by a set of core values that define who we are and how we operate. These values form the foundation of our company and shape every interaction we have with our clients.</p>
            <p><strong><strong>Integrity:</strong></strong> We believe in doing business with honesty, transparency, and ethical practices. We are committed to building trust with our clients, suppliers, and partners by upholding the highest standards of integrity in all that we do. Our integrity drives us to deliver on our promises and ensures that our clients can have complete confidence in the services we provide.</p>
            <p><strong><strong>Excellence:</strong></strong> We are passionate about delivering excellence in every aspect of our work. From the quality of materials we use to the craftsmanship we bring to each project, we strive for nothing less than exceptional results. Our team of skilled professionals is dedicated to continuous improvement, staying up-to-date with industry trends and best practices to ensure we are always at the forefront of our field.</p>
            <p><strong><strong>Customer-Centric Service:</strong></strong> Our clients are at the heart of everything we do. We are committed to providing exceptional customer service and creating a positive experience for each and every client. We listen attentively to your needs, communicate openly, and work closely with you to bring your vision to life. Your satisfaction is our ultimate goal, and we go above and beyond to exceed your expectations.</p>
        </div>

        <div className="mission">
            <h1>Our Mission</h1>
            <p>At Pricision<strong><strong>Fix</strong></strong>, our mission is to transform spaces and inspire lives through our flooring, painting, and stair installation services. We believe that a well-designed and thoughtfully crafted environment has the power to enhance the quality of life, spark creativity, and create lasting impressions.</p>
            <p>We are passionate about creating spaces that reflect the unique style, personality, and functional needs of our clients. Whether it's a residential or commercial project, our team approaches each endeavor with creativity, expertise, and a commitment to delivering results that leave a lasting impact.</p>
            <p>We are dedicated to building long-lasting relationships with our clients, earning their trust through our professionalism, reliability, and dedication to their satisfaction. By combining our expertise, exceptional craftsmanship, and personalized service, we strive to exceed expectations and create spaces that our clients truly love.</p>
        </div>

        
        </div>
       
    </>
    );
}