import React from 'react';
import "./Footer.css";

export default function Footer() {
    return (
    <>
     <div className="container-footer">
            <div>
             <h5>Quick Access</h5>
                <ul>
                    <li>Home</li>
                    <li>Services</li>
                    <li>Galleries</li>
                    <li>About</li>
                    <li>Contact</li>
                </ul>
            </div>
            <div>
                <h5>Services</h5>
                <ul>
                    <li>Flooring</li>
                    <li>Stairs</li>
                    <li>Painting</li>
                </ul>
            </div>        
           
           
     </div>
    </>
    );
}