import "./nav.css"
//import { Link } from 'react-router-dom';
import React from 'react';
//import { Navbar, Container, NavDropdown, Nav } from "react-bootstrap";
import { Navbar, Container, Nav, NavDropdown} from 'react-bootstrap'


import {useState, useEffect } from 'react';
export default function Navigation() {
    
    const [show, SetShow] = useState(false);
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 20) {
                SetShow(true);
            } else {
                SetShow(false);
            }  
        });
        return () => {
            window.removeEventListener('scroll');
        };
    }, []);

    function handleClick() {
        if(!isNavbarOpen){
            setIsNavbarOpen(true)
            SetShow(true)
        }else{ 
            setIsNavbarOpen(false)
        }
    } 


    return (
<header>
    <Navbar  fixed="top" bg={show ? "dark" : 'lg'} variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="/">Precision<strong><strong>Fix</strong></strong></Navbar.Brand>
          <Navbar.Toggle onClick={handleClick} aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" >
            </Nav>
            <Nav>
            <Nav.Link id="menuItem" className="px-4" href="/">Home</Nav.Link>
              <NavDropdown className="px-4" title="Services" id="basic-nav-dropdown">
                <NavDropdown.Item href="/service/floor">Floor Installation</NavDropdown.Item>
                <NavDropdown.Item href="/service/stairs">Stair Installation</NavDropdown.Item>
                <NavDropdown.Item href="/service/paint">Painting</NavDropdown.Item>
              </NavDropdown>
            <Nav.Link href="/about" className="px-4">About</Nav.Link>
            <Nav.Link href="/contact" className="px-4">Contact</Nav.Link>

            </Nav>
          </Navbar.Collapse>
        </Container>
    </Navbar>
</header>


    );

}

