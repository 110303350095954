import "./gallary.css";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"
import "./home.css"
import floorImages from './Image-floor';
import stairImages from './image-stairs';
import { useState } from 'react';

//let path = ['gallary/1.jpg', 'gallary/2.jpg', 'gallary/3.jpg','gallary/4.jpg','gallary/5.jpg','gallary/6.jpg','gallary/7.jpg', 'gallary/8.jpg']
//let count = 1;
export default function Home() {

    let [ images, setImages ] = useState(floorImages.concat(stairImages));



    return (
        <>
        <div id="home-nav-bg">
        <div id="floor-intro"> 
        <h2>Transforming your Spaces with Flooring, Painting, and Stair services</h2>
            <p>Welcome to PrecisionFix, where we specialize in transforming spaces with our comprehensive flooring, painting, and stair services. With our expertise and commitment to excellence, we bring your vision to life, creating beautiful and functional environments that inspire.</p>
            <a href={/contact/}><button>Schedule an estimate</button></a>
        </div>
        </div>

        <div className="home-service">
            <span className="home-flex-left">
            <img src={require('./public/home-floor.jpg')} alt="hardwood floor"/>
            </span>
            <span className="home-flex-right">
            <h2>Flooring</h2>
            <p>Enhance the beauty and functionality of your space with our wide range of flooring options. From luxurious hardwood to versatile tile, durable laminate to cozy carpet, we offer a variety of materials to suit your style and meet your specific needs. Our experienced team ensures precise installation and flawless finishes, leaving you with a stunning floor that will stand the test of time.</p>
            </span>
        </div>

        <div className="home-service">
            <span className="home-flex-left">
            <img src={require('./public/home-paint.jpg')} alt="hardwood floor"/>
            </span>
            <span className="home-flex-right">
            <h2>Paint</h2>
            <p>Revitalize your space with our professional painting services. Our skilled painters are masters of their craft, using top-quality paints and meticulous techniques to transform walls, ceilings, and trim into works of art. Whether you're looking for a fresh coat of paint to breathe new life into a room or want to experiment with vibrant colors, we have the expertise to deliver exceptional results that exceed your expectations.</p>
            </span>
        </div>

        <div className="home-service">
            <span className="home-flex-left">
            <img src={require('./public/home-stairs.jpg')} alt="hardwood floor"/>
            </span>
            <span className="home-flex-right">
            <h2>Stairs</h2>
            <p>Make a statement with a custom staircase that adds elegance and functionality to your space. Our experienced craftsmen create stunning staircases that seamlessly blend with your interior design. From traditional wooden stairs to modern metal and glass designs, we work closely with you to design and install a staircase that elevates the overall aesthetic of your home or commercial space.</p>
            </span>
        </div>

       

        <div className="gallery-title">
            <h1>Galleries</h1>
            <section>
                <ul>
                    <li onClick={() => setImages(floorImages)} key="floor">Floors</li>
                    <li onClick={() => setImages(stairImages)} key="stair"> Stairs</li>
                    <li key="paint">Paint</li>
                </ul>
            </section>
        </div>
        <div className="home-gallery">
            <ImageGallery items={images} />
        </div>

        </>
    );
}

     /*<div className="slider">
                
                   <span style={{"--i": 1}}><img src="gallary/1.jpg"/></span>
                   <span style={{"--i": 2}}><img src="gallary/2.jpg"/></span>
                   <span style={{"--i": 3}}><img src="gallary/3.jpg"/></span>
                   <span style={{"--i": 4}}><img src="gallary/4.jpg"/></span>
                   <span style={{"--i": 5}}><img src="gallary/5.jpg"/></span>
                   <span style={{"--i": 6}}><img src="gallary/6.jpg"/></span>
                   <span style={{"--i": 7}}><img src="gallary/7.jpg"/></span>
                   <span style={{"--i": 8}}><img src="gallary/8.jpg"/></span> 
                
    </div>*/